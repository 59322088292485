import React from "react"
import SEO from "../components/seo"
import Layout from "../components/NewLayout"
import styled from "styled-components"
import { colors } from "Utilities"
import { Card } from "../Elements/Cards"
import StyledHairPageBanner from "../components/hairPageBg"

const HairPage = () => (
  
  <Layout>
  <SEO title="Home" />
  <Container>
    <StyledHairPageBanner>
      <TitleWrapper>
        <h1>Hair treatments at Storm.</h1>
        <p>Under Construction</p>
      </TitleWrapper>
    </StyledHairPageBanner>
  </Container>
  
  
</Layout>
    

)

export default HairPage

const TitleWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  margin-top: 3rem;
  max-width: 500px;
  background-color: rgba(255,255,255,0.7);
  h1 {
    color: ${colors.black};
    font-weight: lighter;
    @media (max-width: 769px) {
      text-align: center;
    }
  }
  
  p{
    text-align: center;
  }
  @media (max-width: 769px) {
    margin-top: 3rem;
  }
  @media (max-width: 469px) {
    margin-top: 8rem;
  }
`

const Container = styled.div`
    width: 100%;
    padding: 1rem 4rem;
    @media (max-width: 769px) {
      padding: 1rem 3rem;
    }
    @media (max-width: 469px) {
      padding: 1rem 1rem;
    }
`